export default {
  localhost: {
    environmentName: 'Local',
    analyticsUrl: 'https://events-logger-stg.data-engineering.prod.asics.digital/events/one',
    apiBaseUrl: 'https://oneasics.dev1.asics.com',
    clientId: 'global_console_local',
    asicsIdBaseUrl: 'https://oneasics.dev1.asics.com',
    redirectUri: `https://localhost:3000${process.env.PUBLIC_URL}/login`,
  },
  'oneasics-console-dev1.dev.asics.digital': {
    environmentName: 'Dev1',
    analyticsUrl: 'https://events-logger-stg.data-engineering.prod.asics.digital/events/one',
    apiBaseUrl: 'https://oneasics.dev1.asics.com',
    asicsIdBaseUrl: 'https://oneasics.dev1.asics.com',
    redirectUri: `https://oneasics-console-dev1.dev.asics.digital${process.env.PUBLIC_URL}/login`,
  },
  'oneasics-console-dev2.dev.asics.digital': {
    environmentName: 'Dev2',
    analyticsUrl: 'https://events-logger-stg.data-engineering.prod.asics.digital/events/one',
    apiBaseUrl: 'https://oneasics.dev2.asics.com',
    asicsIdBaseUrl: 'https://oneasics.dev2.asics.com',
    redirectUri: `https://oneasics-console-dev2.dev.asics.digital${process.env.PUBLIC_URL}/login`,
  },
  'oneasics-console.staging.asics.digital': {
    environmentName: 'Staging',
    analyticsUrl: 'https://events-logger-stg.data-engineering.prod.asics.digital/events/oneasics-analytics-events',
    apiBaseUrl: 'https://id-sandbox.asics.com',
    asicsIdBaseUrl: 'https://id-sandbox.asics.com',
    redirectUri: `https://oneasics-console.staging.asics.digital${process.env.PUBLIC_URL}/login`,
  },
  'partners.one.asics.com': {
    environmentName: 'Production',
    analyticsUrl: 'https://events-logger.data-engineering.prod.asics.digital/events/oneasics-analytics-events',
    apiBaseUrl: 'https://id.asics.com',
    asicsIdBaseUrl: 'https://id.asics.com',
    redirectUri: `https://partners.one.asics.com${process.env.PUBLIC_URL}/login`,
  },
};
